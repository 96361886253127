// Copyright © 2021 Move Closer

import { computed, onMounted, PropType, SetupContext } from '@vue/composition-api'

import { ComponentObjectPropsOptions } from '../../vue-api'
import { canBeDisabledProp } from '../../composables'

import { NavTabsProps } from './NavTabs.contracts'
import { NavTabsItemProps } from './_partials'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl> (original)
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 */
export const navTabsProps: ComponentObjectPropsOptions<NavTabsProps> = {
  ...canBeDisabledProp,

  /**
   * The ID of the currently-active tab.
   */
  active: {
    type: String,
    required: true
  },

  /**
   * List of tabs to render.
   */
  items: {
    type: Array as PropType<NavTabsItemProps[]>,
    required: true
  },

  /**
   * Determine if should use justified tabs.
   */
  justify: {
    type: Boolean,
    required: false,
    default: false
  },

  isWrapped: {
    type: Boolean,
    required: false,
    default: false
  },

  updateQuery: {
    type: Boolean,
    required: false,
    default: false
  }
}

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const useNavTabs = (props: NavTabsProps, ctx: SetupContext) => {
  /**
   * Currently-active tab.
   */
  const activeTab = computed<string>({
    get () {
      return props.active
    },
    set (value): void {
      ctx.emit('update:active', value)
    }
  })

  onMounted(async () => {
    if (!props.updateQuery) {
      return
    }

    await findActiveCandidate()
  })

  /**
   * Activates the tab of a passed-in ID.
   * @param id - The ID of the tab that is to be set as 'active'.
   */
  const activateTab = async (id: string): Promise<void> => {
    if (!props.isDisabled) {
      activeTab.value = id
      await setActivePath(id)
    }
  }

  /**
   * Checks if the tab of a passed-in ID is the currently-active one.
   * @param id - The ID of the tab that is to be checked.
   */
  const isTabActive = (id: string): boolean => {
    return activeTab.value === id
  }

  /**
   * Sets currently active tab as application routing query parameter
   * @param id
   */
  const setActivePath = async (id: string): Promise<void> => {
    if (!props.updateQuery) {
      return
    }

    const router = ctx.root.$router
    const route = ctx.root.$route
    await router.push({ path: route.path, query: { active: id } })
  }

  /**
   * Checks route query params and sets active tab
   */
  const findActiveCandidate = async (): Promise<void> => {
    const route = ctx.root.$route

    if (route.query.active !== props.active) {
      await activateTab(route.query.active.toString())
      return
    }

    await setActivePath(activeTab.value)
  }

  return { activeTab, activateTab, isTabActive }
}
